import { init as initApm } from "@elastic/apm-rum";

const apm = initApm({
  serverUrl: "//apm-rum.inf.miui.com",
  serviceName: "privacy-station",
  serviceVersion: process.env.CI_COMMIT_SHA || "",
  environment: process.env.CI_COMMIT_BRANCH || "local",
});

apm.addLabels({
  group: "mig1-sec-fe",
});
