/* eslint-disable no-underscore-dangle */

// 注意，我开始是用 const {onetrack, _hmt} = windows; 然后打点，无用
// 后改成windows.onetrack, window._hmt打点成功，估计是打点js里重新改了俩值的引用
export function getReferrer() {
  const url = new URL(document.URL);
  const search = new URLSearchParams(url.search);
  return search.getAll("origin")[0];
}

getReferrer();

const daDian = {
  // 访问次数
  visitTimes: () => {
    window.onetrack("track", "view", { tip: "34.2.1.1.479" });
    window._hmt.push(["_trackEvent", "隐私站", "访问次数", "无"]);
  },
  // 访问来源
  origin: () => {
    const ref = getReferrer();
    if (ref) {
      window.onetrack("track", "view", { tip: "34.2.1.1.480", origin: ref });
      window._hmt.push(["_trackEvent", "隐私站", "访问来源", ref]);
    }
  },
  // 访问人数
  visitPeoples: () => {
    window.onetrack("track", "view", { tip: "34.2.1.1.481" });
    window._hmt.push(["_trackEvent", "隐私站", "访问人数", "无"]);
  },
  // 导航点击 - 白皮书
  navWhiteBook: () => {
    window.onetrack("track", "click", { tip: "34.2.2.1.482" });
    window._hmt.push(["_trackEvent", "隐私站", "点击导航白皮书", "无"]);
  },
  // 导航点击 - 隐私政策
  navPrivacy: () => {
    window.onetrack("track", "click", { tip: "34.2.2.1.483" });
    window._hmt.push(["_trackEvent", "隐私站", "点击导航隐私政策", "无"]);
  },
  // 导航点击 - MIUI
  navMiui: () => {
    window.onetrack("track", "click", { tip: "34.2.2.1.484" });
    window._hmt.push(["_trackEvent", "隐私站", "点击导航MIUI", "无"]);
  },
  // 原则页 - 公开透明 - 了解更多
  moreControl: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.485" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "公开透明"]);
  },
  // 原则页 - 数据最小化 - 了解更多
  moreScreen: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.486" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "数据最小化"]);
  },
  // 原则页 - 目的限制 - 了解更多
  moreGoal: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.487" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "目的限制"]);
  },
  // 原则页 - 安全保障 - 了解更多
  moreProtect: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.488" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "安全保障"]);
  },
  // 原则页 - 合规审查 - 了解更多
  moreExamine: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.489" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "合规审查"]);
  },
  // 原则页 - 责任明确 - 了解更多
  moreRespon: () => {
    window.onetrack("track", "click", { tip: "34.3.1.1.490" });
    window._hmt.push(["_trackEvent", "隐私站", "点击了解更多", "责任明确"]);
  },
  // 措施页 - 继续浏览 - MIUI 12 点击
  cotinueMIUI12: () => {
    window.onetrack("track", "click", { tip: "34.4.1.1.491" });
    window._hmt.push(["_trackEvent", "隐私站", "点击继续浏览", "MIUI 12"]);
  },
  // 浏览时长 --- 未使用
  browserTime: () => {
    window.onetrack("track", "view", { tip: "34.2.1.1.492" });
    window._hmt.push(["_trackEvent", "隐私站", "浏览时长", "无"]);
  },
};

setTimeout(() => {
  daDian.visitTimes();
  daDian.origin();
}, 100);
export default daDian;
