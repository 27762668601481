import React from "react";
import { HashRouter as Router } from "react-router-dom";
import Nav from "./pages/Nav";
import Main from "./pages/Main";
import ScrollToTop from "./components/ScrollToTop";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Nav />
      <Main />
    </Router>
  );
}
