import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { NavLink } from "react-router-dom";
import { daDian } from "utils";
import logo from "../../assets/images/logo.svg";
import Arrow1 from "assets/images/arrow-nav1.svg";
import Arrow2 from "assets/images/arrow-nav2.svg";
import "./index.scss";

function Nav(props) {
  useEffect(() => {
    const activeLink = document.querySelector(".Nav__Link.active");
    // eslint-disable-next-line no-unused-expressions
    activeLink && activeLink.click();
  }, []);

  return createPortal(
    <nav>
      <div className="Nav__container">
        <section className="Nav__section Nav__section--center">
          <a
            onClick={() => {
              const { origin, pathname } = window.location;
              window.location = origin + pathname;
            }}
          >
            <img
              style={{ cursor: "pointer", width: "13.6rem" }}
              src={logo}
              alt=""
            />
          </a>
        </section>
        <section className="Nav__section ">
          <ul className="Nav__ul">
            <li className="Nav__li">
              <NavLink to="/" className="Nav__Link" exact>
                原则
              </NavLink>
            </li>
            <li className="Nav__li">
              <NavLink to="/functionality" className="Nav__Link">
                功能
              </NavLink>
            </li>
            <li className="Nav__li">
              <NavLink to="/admin-privacy" className="Nav__Link">
                管理隐私
              </NavLink>
            </li>
            <li className="Nav__li">
              <NavLink to="/protect-methods" className="Nav__Link">
                安全措施
              </NavLink>
            </li>
            {/* <li className="Nav__li">
              <a
                href={`${process.env.PUBLIC_URL}/files/privacy.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                className="Nav__Link"
                onClick={daDian.navWhiteBook}
              >
                白皮书
              </a>
            </li> */}
            <li className="Nav__li">
              <a
                href="https://privacy.mi.com/all/zh_CN/"
                target="_blank"
                className="Nav__Link"
                rel="noopener noreferrer"
                onClick={daDian.navPrivacy}
              >
                隐私政策
              </a>
            </li>
            <li className="Nav__li">
              <a
                href="https://hyperos.mi.com/"
                className="Nav__Link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={daDian.navMiui}
              >
                小米澎湃OS
              </a>
            </li>
            <li className="Nav__li">
              <a
                href="https://trust.mi.com/ "
                className="Nav__Link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={daDian.navMiui}
              >
                信任中心
              </a>
            </li>
          </ul>
        </section>
      </div>
      <section className="Nav__language Nav__Link">
        <div>
          <sapn className="Nav__title">中文（简体）</sapn>
          <img className="Nav__arrow1" src={Arrow1}></img>
          <img className="Nav__arrow2" src={Arrow2}></img>
        </div>

        <div className="Language__container">
          <div className="Item__wrapper">
            <div className="Language__item">中文（简体）</div>
          </div>
          <div
            onClick={() => {
              window.location.href = `${window.location.origin}/en/#/`;
            }}
            className="Item__wrapper"
          >
            <div className="Language__item">English</div>
          </div>
        </div>
      </section>
    </nav>,
    document.getElementById("nav"),
  );
}

export default Nav;
