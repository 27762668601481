import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";

import "./index.scss";

// import Rule from './Rule';
// import Functionality from './Functionality';
// import AdminPrivacy from './AdminPrivacy';
// import ProtectMethods from './ProtectMethods';

const Rule = lazy(() => import("./Rule"));
const Functionality = lazy(() => import("./Functionality"));
const AdminPrivacy = lazy(() => import("./AdminPrivacy"));
const ProtectMethods = lazy(() => import("./ProtectMethods"));

export default function Main() {
  return (
    <Suspense fallback={<div>loading....</div>}>
      <Route exact path="/" component={Rule} />
      <Route path="/functionality" component={Functionality} />
      <Route path="/admin-privacy" component={AdminPrivacy} />
      <Route path="/protect-methods" component={ProtectMethods} />
    </Suspense>
  );
}
